import {OPEN_MODAL, CLOSE_MODAL} from './types'

export const open = name => {
  return {
    type: OPEN_MODAL,
    payload: name
  }
}

export const close = () => {
  return {
    type: CLOSE_MODAL,
  }
}


