import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Steps.css";
import { useLocation, useNavigate } from "react-router-dom";
import { TextField, Button } from '@mui/material';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';

import axios from "axios";

function Step1(props) {
  const location = useLocation();

  const navigate = useNavigate();
  
  const dispatch = useDispatch();

  const updateField = (key, value) => {
   
    props.setFormData({...props.formData, [key] : value})
  }

  const Next = () => {
    if(!lname || !fname || !id_number) {
      return;
    }
    props.setFormData({...props.formData, 
        id_number : id_number,
        fname: fname,
        lname: lname
    })
    props.next()
  }
  const [id_number, set_id_number] = useState(props.formData.id_number)  
  const [fname, set_fname] = useState(props.formData.fname)  
  const [lname, set_lname] = useState(props.formData.lname)  
  return (
    <div className="step step1" onKeyUp={(e) =>  {
      if(e.key == 'Enter') {
        Next()
      }
    }}>
        <h3>עבור מי התור המבוקש?</h3>
        
        <TextField            
              value={id_number}   
              required
              label="מספר תעודת זהות"
              onChange={(e) => set_id_number(e.target.value)}
              placeholder={"אנא הזן מספר תעודת זהות"}
              type="tel"
              className="input_tel"
            />
            <TextField            
              required
              value={fname}   
              label="שם פרטי"
              onChange={(e) =>  set_fname(e.target.value)}
              placeholder={"שם פרטי (כפי שמופיע בתעודת זהות)"}
            />
            <TextField            
              required
              value={lname}   
              label="שם משפחה"
              onChange={(e) =>  set_lname(e.target.value)}
              placeholder={"שם משפחה (כפי שמופיע בתעודת זהות)"}
            />
    
         <Button variant="contained" 
            disabled={!lname || !fname || !id_number} 
            className="next-btn" 
            onClick={Next} 
            sx={{ borderRadius: 0, fontSize: 18 }}
            endIcon={<ChevronLeftRoundedIcon />}
            >הבא</Button>
    </div>
  );
}

export default Step1;
