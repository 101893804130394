import {  useRef, useState } from "react";
import {  useSelector } from "react-redux";
import "./Steps.css";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';

function Step4(props) {
  const {user} = useSelector((state) => state.auth)
  const department = user?.departments.find(d => d.id == props.formData.department_id)
 
  const slots = props.formData.doctor.slots.sort((a, b) => dayjs(a.wdate).isBefore(dayjs(b.wdate)));
  const firstSlot = slots[0]
  const initialValue = dayjs(firstSlot.wdate);
 const [highlightedDays, setHighlightedDays] = useState(
 slots.filter(s =>
 dayjs(s.wdate).isSame(dayjs(initialValue).startOf('month')) || 
(dayjs(s.wdate).isBefore(dayjs(initialValue).add(1, 'month').startOf('month')) &&  
dayjs(s.wdate).isAfter(dayjs(initialValue).startOf('month'))))
    .map(s => Number(dayjs(s.wdate).format('DD')))
  );
  

  const [currentDate, setCurrentDate] = useState('')
  const [times, setTimes] = useState(slots.filter(s => s.wdate == initialValue.format('YYYY-MM-DD')));
  const requestAbortController = useRef(null);
  
const ServerDay = (props) => {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

  return (
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} disabled={!isSelected} day={day} />

  );
}

const Change = (value) => {
  setCurrentDate(value);
  let times = slots.filter(s => s.wdate == value.format('YYYY-MM-DD'));
  setTimes(times)
}

const ChooseTime = (time) => {
  
  props.setFormData({...props.formData, 
    slot_data_id: time.id,
    appointed_time: time.start_time,
    timeData: time,
  })
  props.next()
}

const handleMonthChange = (date) => {
 
  setTimes([])
 

  
     setHighlightedDays(
    slots.filter(s => 
   dayjs(s.wdate).isSame(dayjs(date).startOf('month')) || (dayjs(s.wdate).isBefore(date.add(1, 'month').startOf("month")) && dayjs(s.wdate).isAfter(dayjs(date).startOf('month'))))
    .map(s => Number(dayjs(s.wdate).format('DD')))
  );
        

};
  
  return (
    <div className="step step4">   
      <h3>{department?.title}</h3>
      <h5>{props.formData.doctor.title}</h5>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        views={['day']}
        defaultValue={initialValue}
        onChange={Change}
        //loading={isLoading}
        onMonthChange={handleMonthChange}
       // renderLoading={() => <DayCalendarSkeleton />}
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            highlightedDays,
          },
        }}
      />
      </LocalizationProvider>

      <div className="time-warp">  
      {times.map(time => (
        <span className="time-slot" onClick={() => ChooseTime(time)}>{
          dayjs('2023-12-12 ' + time.start_time).format('HH:mm')
          }</span>
      ))}
      </div>
        
    </div>
  );
}

export default Step4;
