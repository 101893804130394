import axios from 'axios'

const setupAxios = (accessToken, formFunc) => {
  // ** Request Interceptor
  
  axios.interceptors.request.use(
    config => {
      if (accessToken) {
        if (!config.headers.common) {
          config.headers['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
        } else {
          config.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;//accessToken}`
        
        }
      } else {
        delete axios.defaults.headers.common['Authorization']
      }

      return config
    },
    error => Promise.reject(error)
  )

  


}

const setAuthToken = accessToken => {
  console.log(accessToken)
  setupAxios(accessToken, true)
}

export default setAuthToken
