import {
    ASYNC_ACTION_START,
    ASYNC_ACTION_FINISH,
    ASYNC_ACTION_ERROR,
    ASYNC_ACTION_CLEAN_ERROR
} from './types';
  
  export const asyncActionStart = (payload) => {
  
    return {
      type: ASYNC_ACTION_START,
      payload: payload
    };
  };
  
  export const asyncActionFinish = (payload) => {
    return {
      type: ASYNC_ACTION_FINISH,
      payload: payload
    };
  };
  
  export const asyncActionError = (error) => {
    
    return {
      type: ASYNC_ACTION_ERROR,
      payload: error,
    };
  };
  
  export const asyncActionCleanError = () => {
    return {
      type: ASYNC_ACTION_CLEAN_ERROR
    };
  };
  