import axios from "axios";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "./asyncActions";
import {  REMOVE_VISITOR, LOGOUT, START_FORM, USER_DATA, ADD_VISITOR, FAILED,UPDATE_VISITOR } from "./types";
import { API_URL } from "./api";
import { open } from "./modalActions";


export const getHomeData = (docNum) => async (dispatch) => {
  dispatch(asyncActionStart());
  try {
    const url = `${API_URL}user/me`;

    const res = await axios.get(url, { docNum: docNum });

    if (res.error) {
     
      dispatch(asyncActionError(res.error));
    } else {
      dispatch({
        type: USER_DATA,
        payload: res.data.data,
      });

      dispatch(asyncActionFinish());
    }
  } catch (error) {
    console.log(error);
    if (error?.response.status == 401) {
      dispatch({
        type: LOGOUT,
      });
      dispatch(asyncActionError("אנא היכנס מחדש"));
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      dispatch(asyncActionError(error.response.data.message));
    } else {
      dispatch(asyncActionError("Server error"));
    }
  }
};



export const sendAppointment = (formData) => async dispatch => {
  dispatch(asyncActionStart())
  try {   
    const res = await axios.post(`${API_URL}visitors`, formData)
    if(res.data.data.error) {
        
         dispatch({
          type: FAILED,
          payload: res.data.data
        });
        dispatch(asyncActionError(res.data.data.error))
    } else {
       
      console.log(res.data)
        dispatch({
          type: ADD_VISITOR,
          payload: res.data.data
        })
        dispatch(open('snack_bar'))
        dispatch(asyncActionFinish())
    }
   
  } catch (error) {    
    if (error.response && error.response.data && error.response.data.title) {
      // dispatch(asyncActionError(error.response.data.title))
      dispatch(asyncActionError(error.response.data))
    } else {     
        dispatch(asyncActionError('Server error'))    
    }
  }
}



export const deleteAppointment = (id) => async dispatch => {
  dispatch(asyncActionStart())
  try {   
    const res = await axios.delete(`${API_URL}visitors/${id}`)
   
    if(res.status == 204) {
     
        dispatch({
          type: REMOVE_VISITOR,
          payload: id
        })
        dispatch(open('snack_bar'))
        dispatch(asyncActionFinish())
    }
   
  } catch (error) {    
    if (error.response && error.response.data && error.response.data.title) {
      // dispatch(asyncActionError(error.response.data.title))
      dispatch(asyncActionError(error.response.data))
    } else {     
        dispatch(asyncActionError('Server error'))    
    }
  }
}


export const updateAppointment = (id, formData) => async dispatch => {
  dispatch(asyncActionStart())
  try {   
    const res = await axios.put(`${API_URL}visitors/${id}`, formData)
   
   
     if(res.status == 200) {
      console.log(res)
        dispatch({
          type: UPDATE_VISITOR,
          payload: res.data.data
        })
        dispatch(open('snack_bar'))
        dispatch(asyncActionFinish())
    }
   
  } catch (error) {    
    if (error.response && error.response.data && error.response.data.title) {
      // dispatch(asyncActionError(error.response.data.title))
      dispatch(asyncActionError(error.response.data))
    } else {     
        dispatch(asyncActionError('Server error'))    
    }
  }
}


export const startForm = (row) => {
  return {
    type: START_FORM,
  };
};





