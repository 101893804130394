import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Header from '../layout/header/Header'
import Login from '../auth/login/Login'
import {setAsAuthenticated, getMeDetails} from '../../redux/actions/authActions'
import './Content.css'
import Footer from '../layout/footer/Footer'
import { getHomeData } from '../../redux/actions/dataActions'
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Form from "../form/Form"
import Appointments from '../appointments/Appointments'
import Appointment from '../appointments/Appointment'
import Snackbar from '@mui/material/Snackbar';
import { close } from '../../redux/actions/modalActions'



function Content() {
  
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const { modal_name } = useSelector((state) => state.modal);

  const dispatch = useDispatch()

  
  useEffect(() => {
    
    if (!isAuthenticated && localStorage.getItem('token') ) {
      dispatch(setAsAuthenticated(localStorage.getItem('token')))
      dispatch(getHomeData())
    }  
  }, [])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(close())
  }


  return (
    <div className='content_page' dir="rtl">
      <div className='content'>
        <Header /> 
        {!isAuthenticated ? <Login />
        : <Routes>
            <Route key={"form"} path={"/form"} element={<Form />} />
            <Route key={"home"} path={'/'} element={<Appointments />} />
            <Route key={"appointment"} path={"appointment/:id"} element={<Appointment/>}  />
                  
             </Routes>}
             <Snackbar
                open={modal_name == 'snack_bar'}
                autoHideDuration={2000}
                onClose={handleClose}
                message="הפעולה בוצעה בהצלחה"
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
              />

      </div>
    </div>
  )
}

export default Content
