import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Steps.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Button } from '@mui/material';

import axios from "axios";
import { sendAppointment } from "../../../redux/actions/dataActions";
import AddTaskRoundedIcon from '@mui/icons-material/AddTaskRounded';

function StepFailedAppoint(props) {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth)
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const department = user?.departments.find(d => d.id == props.formData.department_id)

  const Next = () => {
    navigate('/')
  }

  return (
    <div className="step step5">
      <h3>{props?.formData.fname}</h3>
      <h5 className="validate_date_failed"> מצטערים, אין יותר תורים פנוים לשעה שבחרתם</h5>
      {/* <AddTaskRoundedIcon /> */}
      
      <Card>
        <div className="dep_title">{department.title}</div>
        <div>{props?.formData.doctor.title}</div>
        <br />
        <div><b>{props?.formData.doctor.address}</b></div>

        <br />
        <div className="flex_el">
          <div>
            תאריך <br />
            <b>{props.formData.timeData.wdate}</b>
          </div>
          <div>
            שעה <br />
            <b>{props.formData.timeData.start_time}</b>
          </div>
        </div>

      </Card>

      <Button variant="contained"
        className="next-btn" onClick={Next}>סיום</Button>

    </div>
  );
}

export default StepFailedAppoint;
