import {combineReducers} from 'redux'
import authReducer from './authReducer'
import asyncReducer from './asyncReducer'
import modalReducer from './modalReducer'
import dataReducer from './dataReducer'



const rootReducer = combineReducers({
  auth: authReducer,
  async: asyncReducer,
  modal: modalReducer,
  data: dataReducer

})

export default rootReducer
